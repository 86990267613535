import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

import NavBar from "./NavBar";
import TopBar from "./TopBar";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    width: "100%",
    height: "100%",
    overflow: "hidden"
  },

  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,

    [theme.breakpoints.up("lg")]: {
      paddingLeft: 240
    }
  },

  contentContainer: {
    backgroundColor: "#f8f9fc",
    width: "100%",
    minHeight: window.innerHeight - 100,
    overflowX: "hidden",
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    padding: 15
  },

  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    marginTop: theme.spacing(2)
  }
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NavBar />
      <div className={classes.wrapper}>
        <TopBar />
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
