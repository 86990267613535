import { useContext } from "react";
import AuthContext from "src/contexts/JWTAuthContext";
import { IUserDetail } from "src/models/IUserDetail";

const useAuth = (): {
  login: () => Promise<void>;
  logout: () => void;
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: null | IUserDetail;
} => useContext(AuthContext);

export default useAuth;
