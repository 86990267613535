import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, makeStyles, Toolbar } from "@material-ui/core";
import Logo from "src/components/Logo";
import UserInfo from "src/components/UserInfo";
import { GetWebCommon, showLoading } from "src/redux/store";
import { setCommonViewData } from "src/actions";

const useStyles = makeStyles(() => ({
  root: {
    color: "#000",
    backgroundColor: "#fff",

    "& .MuiToolbar-gutters": {
      paddingLeft: "0 !important"
    }
  },
  logoView: {
    backgroundColor: "#354ea9",
    width: 241,
    minHeight: 64,
    padding: "5px 0px"
  },
  webTitle: {
    fontWeight: 600,
    marginBottom: "5px",
    textTransform: "uppercase"
  },
  webSubTitle: {
    textTransform: "uppercase"
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const commonViewData = useSelector(state => state.home.commonViewData);

  const _GetWebCommon = () => {
    if (!_.isEmpty(commonViewData)) return;
    dispatch(showLoading(true));
    GetWebCommon()
      .then(res => {
        dispatch(setCommonViewData(res));
        dispatch(showLoading(false));
      })
      .catch(err => {
        dispatch(showLoading(false));
      });
  };

  useEffect(() => {
    _GetWebCommon();
  }, []);

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoView}>
          <RouterLink to="/" style={{ textDecoration: "none" }}>
            <Box textAlign="center" paddingTop="10px">
              <Logo
                width={50}
                height={50}
                src={
                  commonViewData?.webLogo
                    ? `data:image/png;base64,  ${commonViewData.webLogo}`
                    : "../static/images/logo-tuyen-quang.png"
                }
              />
            </Box>
          </RouterLink>
        </div>
        <Box flexGrow={1} marginLeft={2}>
          <Box className={classes.webTitle}>{commonViewData?.webTitle}</Box>
          <Box className={classes.webSubTitle}>
            {commonViewData?.webSubTitle}
          </Box>
        </Box>
        <UserInfo />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
