import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { ReduxSliceName } from "src/constants/ReduxSliceName";
import { IScreen } from "src/models/IScreen";
import { useSetLoading } from "../app";
import { axiosInstance } from "src/service/ApiService";
import { API_URL } from "src/constants";
import { errorNotification } from "src/utils/toastNotification";

export interface State {
  screens: IScreen[];
}

const initialState: State = {
  screens: [],
};

export const permissionSlice = createSlice({
  name: ReduxSliceName.Permission,
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<Partial<State>>) => {
      const keys = Object.keys(action.payload);
      for (let propertyName in action.payload) {
        state[propertyName] = action.payload[propertyName];
      }
    },
  },
});
const { setState } = permissionSlice.actions;
export const PermissionReducer = permissionSlice.reducer;

export const usePermission = () => {
  const { closeLoading, displayLoading } = useSetLoading();
  const dispatch = useDispatch();
  const { screens }: State = useSelector(
    (state) => state[ReduxSliceName.Permission]
  );
  const getScreensLookup = async () => {
    if (screens.length) return;
    displayLoading();
    try {
      const res = (await axiosInstance.get(API_URL.GetScreenLookup)).content;
      const initScreens: IScreen[] = res.map((screen) => ({
        id: screen.id,
        code: screen.name,
      }));
      dispatch(
        setState({
          screens: initScreens,
        })
      );
    } catch (e) {
      errorNotification(
        "Có lỗi xảy ra trong quá trình lấy dữ liệu - danh sách màn hình, vui lòng thử lại sau"
      );
    } finally {
      closeLoading();
    }
  };
  return { screens, getScreensLookup };
};
