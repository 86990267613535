import React from "react";
import { Box, Avatar, Menu, MenuItem } from "@material-ui/core";
import useAuth from "src/hooks/useAuth";
import { useBoolean } from "src/hooks/useBoolean";
import { ChangePassword } from "./components/ChangePassword";
import { getFileUrl } from "src/utils/getFileUrl";

const UserInfo = () => {
  const { logout, user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [
    isShowChangePassword,
    closeChangePassword,
    showChangePassword
  ] = useBoolean(false);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };
  const handleChangePassword = () => {
    showChangePassword();
    handleClose();
  };
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        <Box mr={1} align="right">
          <Box>{user && user.fullName}</Box>
        </Box>
        {user && user.avatar ? (
          <Avatar src={getFileUrl(user.avatar)} />
        ) : (
          <Avatar>{user && user.fullName && user.fullName.charAt(0)}</Avatar>
        )}
      </Box>
      <Menu
        id="user-profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={handleChangePassword}>Đổi mật khẩu</MenuItem>
        <MenuItem onClick={handleLogout}>Đăng xuất</MenuItem>
      </Menu>
      {isShowChangePassword && <ChangePassword onClose={closeChangePassword} />}
    </>
  );
};

export default UserInfo;
