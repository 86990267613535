//@ts-check
import React, { forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import { FieldName } from "src/constants/FieldName";
import Input, { FilledInputProps } from "@material-ui/core/FilledInput";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useBoolean } from "src/hooks/useBoolean";
import styles from "./styles.module.scss";
import { InputErrorMessage } from "../InputErrorMessage";

interface ExtendProps {
  fieldName: FieldName;
  validate?: any;
  pattern?:
    | {
        value: RegExp;
        message: string;
      }
    | RegExp;
  setValueAs?: (v: any) => any;
}

type Props = ExtendProps & PasswordTextFieldProps;

export const PasswordTextFieldControl = ({
  fieldName,
  required,
  label,
  validate,
  pattern,
  setValueAs,
  ...restProps
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [isShowPassword, hidePassword, showPassword] = useBoolean(false);
  const { onChange, onBlur, ref } = register(fieldName, {
    required: required && "Vui lòng hoàn thành trường bắt buộc",
    validate,
    pattern,
    setValueAs,
  });
  const errorMessage = errors?.[fieldName]?.message as string;
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <PasswordTextField
      error={Boolean(errorMessage)}
      fullWidth
      helperText={errorMessage}
      name={fieldName}
      onBlur={onBlur}
      onChange={onChange}
      required={required}
      ref={ref}
      label={label}
      type={isShowPassword ? "text" : "password"}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={isShowPassword ? hidePassword : showPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {isShowPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
      {...restProps}
    />
  );
};

type PasswordTextFieldProps = FilledInputProps & {
  containerClassName?: string;
  label?: any;
  helperText?: string;
};

export const PasswordTextField = forwardRef(
  (
    {
      required,
      label,
      containerClassName,
      helperText,
      ...restProps
    }: PasswordTextFieldProps,
    ref: any
  ) => {
    return (
      <div className={clsx(styles.input, containerClassName)}>
        {label && (
          <Box marginY={1}>
            {label}
            {required && <span className="required"></span>}
          </Box>
        )}
        <Input
          disableUnderline
          fullWidth
          required={required}
          ref={ref}
          {...restProps}
        />
        <InputErrorMessage message={helperText} />
      </div>
    );
  }
);
