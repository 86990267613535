export enum ROUTER_NAME {
  ALL = "*",
  DEFAULT = "/",
  NOT_FOUND = "/404",
  LOGIN = "/dang-nhap",
  REGISTER = "/dang-ki",
  TOOL = "/cong-cu",
  TOOL_TYPE = "/loai-cong-cu",
  MAP_LAYER = "/lop-ban-do",
  MAP_DOCUMENT = "/tai-lieu-ban-do",
  FORGOT_PASSWORD = "/quen-mat-khau",
  RESET_PASSWORD = "/thay-mat-khau",
  NEWS = "/tin-tuc",
  USER_MANAGEMENT = "/quan-ly-nguoi-dung",
  GUIDE = "/huong-dan",
  FEEDBACK = "/phan-hoi",
  TERM = "/dieu-khoan",
  DOCUMENT = "/van-ban",
  BASE_MAP_SETTING = "/ban-do-nen",
  NORMAL_INFO = "/thong-tin-chung",
  MANAGE_LAND_PRICE = "/quan-ly-gia-dat",
  FEEDBACK_INFO = "/thong-tin-gop-y",
  UPDATE_GOVERNMENT_PRICE = "/cap-nhat-gia-nha-nuoc",
  UPDATE_INVESTIGATE_PRICE = "/cap-nhat-gia-dieu-tra",
  WARDS_MANAGE = "/quan-ly-danh-sach-phuong",
  ACCOUNT_TYPE = "/quan-ly-loai-tai-khoan",
  AUCTION_PROJECT = "/quan-ly-du-an-dau-gia",
  LAND_TYPE = "/quan-ly-loai-dat",
  SYSTEM_LOG = "/quan-ly-nhat-ky-he-thong",
  FILE_ATTACHMENT = "/quan-ly-tep-dinh-kem",
  LAND_PRICE_TABLE = "/quan-ly-bang-gia-dat",
  PRICE_FOR_EACH_PLOT = "/quan-ly-gia-dat-toi-tung-thua",
}
