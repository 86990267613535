import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import {
  createTheme,
  jssPreset,
  StylesProvider,
  ThemeProvider
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { AuthProvider } from "src/contexts/JWTAuthContext";
import routes, { renderRoutes } from "src/routes";
import Loading from "src/components/Loading";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "suneditor/dist/css/suneditor.min.css";
import "moment/locale/vi";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const theme = createTheme();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider locale="vi" utils={MomentUtils}>
          <SnackbarProvider
            dense
            maxSnack={3}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <Loading />
            <Router history={history}>
              <ToastContainer />
              <AuthProvider>{renderRoutes(routes)}</AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
