import { FormHelperText } from "@material-ui/core";
import styles from "./styles.module.scss";

interface Props {
  message?: string;
}
export const InputErrorMessage = ({ message }: Props) => {
  if (!message) return null;
  return (
    <FormHelperText className={styles.error} error>
      {message}
    </FormHelperText>
  );
};
