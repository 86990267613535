import { ACTIONS_TYPES } from "src/constants";

const initialState = {
  commonViewData: {}
};

export const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.SET_WEB_COMMON_VIEW:
      return {
        ...state,
        commonViewData: action.payload?.commonViewData
      };
    default:
      return state;
  }
};
