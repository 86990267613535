import Service from "src/service/ApiService";
import { API_URL } from "src/constants";

const service = new Service();

export const Login = async data => {
  try {
    const res = await service.post(API_URL.Login, data);
    return res;
  } catch (err) {
    throw err;
  }
};
export const Register = async data => {
  try {
    const res = await service.post(API_URL.Register, data);
    return res;
  } catch (err) {
    throw err;
  }
};
export const ForgotPassword = async data => {
  try {
    const res = await service.post(API_URL.ForgotPassword, data);
    return res;
  } catch (err) {
    throw err;
  }
};
export const ResetPassword = async data => {
  try {
    const res = await service.post(API_URL.ResetPasswordAccount, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const GetUserAccountDetail = async () => {
  try {
    const res = await service.get(API_URL.GetUserAccountDetail);
    return res;
  } catch (err) {
    throw err;
  }
};
