import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Modal } from "src/components/Modal";
import { PasswordTextFieldControl } from "src/components/PasswordTextFieldControl";
import { API_URL } from "src/constants";
import { FieldName } from "src/constants/FieldName";
import useAuth from "src/hooks/useAuth";
import { axiosInstance } from "src/service/ApiService";
import { getErrorMsg } from "src/utils";
import {
  errorNotification,
  successNotification,
} from "src/utils/toastNotification";
import { passwordValidate } from "src/utils/validate/password";

interface Props {
  onClose: () => void;
}

export const ChangePassword = ({ onClose }: Props) => {
  const form = useForm();
  const { logout } = useAuth();

  const onSubmit = async (values) => {
    try {
      const body = new FormData();
      body.set(FieldName.NewPassword, values[FieldName.NewPassword]);
      body.set(FieldName.OldPassword, values[FieldName.OldPassword]);
      await axiosInstance.post(API_URL.ChangePassword, body);
      successNotification(
        "Đổi mật khẩu thành công, vui lòng đăng nhập lại bằng mật khẩu vừa thay đổi."
      );
      logout();
    } catch (e) {
      errorNotification(getErrorMsg(e));
    }
  };
  const [repeatNewPasswordValidate] = useState({
    ...passwordValidate,
    notMatch: (v) => {
      const inputValue = v?.trim();
      const newPassword = form.watch(FieldName.NewPassword)?.trim();
      return (
        !inputValue ||
        !newPassword ||
        inputValue === newPassword ||
        "Nhập lại mật khẩu mới không khớp"
      );
    },
  });
  return (
    <Modal
      isOpen
      onClose={onClose}
      okLabel="Đổi mật khẩu"
      onOk={form.handleSubmit(onSubmit)}
      title="Đổi mật khẩu"
    >
      <div className="max-w-xl">
        <FormProvider {...form}>
          <PasswordTextFieldControl
            fieldName={FieldName.OldPassword}
            label="Nhập mật khẩu cũ"
            required
            validate={passwordValidate}
            autoFocus
          />
          <PasswordTextFieldControl
            fieldName={FieldName.NewPassword}
            label="Nhập mật khẩu mới"
            required
            validate={passwordValidate}
          />
          <PasswordTextFieldControl
            fieldName={FieldName.ReInputNewPassword}
            label="Nhập lại mật khẩu mới"
            required
            validate={repeatNewPasswordValidate}
          />
        </FormProvider>
      </div>
    </Modal>
  );
};
