export const ACTIONS_TYPES = {
  SET_DEFAULT_STEP: "SET_DEFAULT_STEP",
  SET_TOTAL_STEP: "SET_TOTAL_STEP",
  NEXT_STEP: "NEXT_STEP",
  PREV_STEP: "PREV_STEP",
  SET_CREATE_LAYER_SETTING: "SET_CREATE_LAYER_SETTING",

  SET_MAP_LAYER_DATA: "SET_MAP_LAYER_DATA",

  SET_WEB_COMMON_VIEW: "SET_WEB_COMMON_VIEW"
};
