import { Regex } from "src/constants/Regex";

export const passwordValidate = {
  isValid: v => {
    const inputValue = v?.trim();
    return (
      !inputValue ||
      (inputValue.length > 7 && Regex.password.test(inputValue)) ||
      "Mật khẩu sẽ gồm tối thiểu 8 ký tự: Có it nhất 1 ký tự viết thường, hoa và ký tự đặc biệt"
    );
  }
};
