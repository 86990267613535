import Service from "src/service/ApiService";
import { API_URL } from "src/constants";

const service = new Service();

export const GetHomePageMapViewData = async (mapId = 1) => {
  try {
    const params = new URLSearchParams();
    params.append("mapId", mapId);
    const res = await service.get(API_URL.GetHomePageMapViewData, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const UpdateLayer = async (data, files) => {
  try {
    const formData = new FormData();
    formData.append("json", JSON.stringify(data));
    if (files && files.length > 0) {
      files.map(file => {
        formData.append("images", file);
      });
    }
    const res = await service.post(API_URL.UpdateLayer, formData);
    return res;
  } catch (err) {
    throw err;
  }
};
export const GetPgSchema = async () => {
  try {
    const res = await service.get(API_URL.GetPgSchema);
    return res;
  } catch (err) {
    throw err;
  }
};
