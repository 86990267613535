import Service from "src/service/ApiService";
import { API_URL } from "src/constants";
import moment from "moment";

const service = new Service();

export const GetMapDocumentByFolderId = async (
  folderId,
  sortExpression,
  name
) => {
  try {
    const params = new URLSearchParams();
    folderId && params.append("folderId", folderId);
    sortExpression && params.append("sortExpression", sortExpression);
    name && params.append("name", name);
    const res = await service.get(API_URL.GetMapDocumentByFolderId, params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const GetMapDocumentFolders = async () => {
  try {
    const res = await service.get(API_URL.GetMapDocumentFolders);
    return res;
  } catch (err) {
    throw err;
  }
};
export const CreateMapDocumentCategory = async data => {
  try {
    const res = await service.post(API_URL.CreateMapDocumentCategory, data);
    return res;
  } catch (err) {
    throw err;
  }
};
export const UpdateMapDocumentCategory = async data => {
  try {
    const res = await service.post(API_URL.UpdateCategory, data);
    return res;
  } catch (err) {
    throw err;
  }
};
export const DeleteMapDocumentCategory = async id => {
  try {
    const params = new URLSearchParams();
    params.append("Id", id);
    const res = await service.postParams(
      API_URL.DeleteMapDocumentCategory,
      params
    );
    return res;
  } catch (err) {
    throw err;
  }
};
export const DeleteMapDocument = async data => {
  try {
    const params = new URLSearchParams();
    (data || []).map(({ id }) => {
      params.append("Ids", id);
    });

    const res = await service.postParams(API_URL.DeleteMapDocument, params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const CreateMapDocument = async data => {
  const formData = new FormData();
  formData.append("Name", data.name);
  formData.append("DocumentNumber", data.documentNumber);
  formData.append("YearEstablish", data?.yearEstablish);
  formData.append("DateUpload", data?.dateUpload || "");
  formData.append("FilePart", data?.filePart || "");
  formData.append("FileExtension", data?.fileExtension || "");
  formData.append("Thumbnail", data?.thumbnail || "");
  formData.append("UnitEstablish", data.unitEstablish || "");
  formData.append("Status", data?.status || "");
  formData.append("CategoryId", data?.categoryId);
  formData.append("file", data?.file);
  formData.append("thumbnail", data?.thumbnail);
  try {
    const res = await service.post(API_URL.CreateMapDocument, formData);
    return res;
  } catch (err) {
    throw err;
  }
};
export const UpdateMapDocument = async data => {
  try {
    const formData = new FormData();
    formData.append("Id", data.id);
    formData.append("Name", data.name);
    formData.append("DocumentNumber", data.documentNumber);
    data?.yearEstablish &&
      formData.append("YearEstablish", data?.yearEstablish);
    formData.append("DateUpload", data?.dateUpload || "");
    formData.append("FilePart", data?.filePart || "");
    formData.append("FileExtension", data?.fileExtension || "");
    formData.append("Thumbnail", data?.thumbnail || "");
    formData.append("UnitEstablish", data.unitEstablish || "");
    formData.append("Status", data?.status || "");
    formData.append("CategoryId", data?.categoryId);
    formData.append("file", data?.file);
    formData.append("thumbnail", data?.thumbnailTemp || "");
    const res = await service.post(API_URL.UpdateMapDocument, formData);
    return res;
  } catch (err) {
    throw err;
  }
};
