import { ACTIONS_TYPES } from "src/constants";

export const setMapLayerData = mapLayers => ({
  type: ACTIONS_TYPES.SET_MAP_LAYER_DATA,
  payload: {
    mapLayers
  }
});
export const setCreateLayerSettingData = createLayerSetting => ({
  type: ACTIONS_TYPES.SET_CREATE_LAYER_SETTING,
  payload: createLayerSetting
});
