import { combineReducers } from "@reduxjs/toolkit";
import { reducer as formReducer } from "redux-form";

import {
  SettingLayerReducer,
  AppReducer,
  mapLayersReducers,
  AddLayerSettingReducer,
  HomeReducer,
  PermissionReducer
} from "./store";
import { ReduxSliceName } from "src/constants/ReduxSliceName";

const rootReducer = combineReducers({
  form: formReducer,
  app: AppReducer,
  mapLayers: mapLayersReducers,
  addLayer: AddLayerSettingReducer,
  settingLayer: SettingLayerReducer,
  home: HomeReducer,
  [ReduxSliceName.Permission]: PermissionReducer
});

export default rootReducer;
