import { toast } from 'react-toastify'

const TOAST_TYPE = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  DEFAULT: 'default',
  DARK: 'dark'
}

const TOAST_POSITION = {
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  TOP_CENTER: 'top-center',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_CENTER: 'bottom-center'
}

const defaultStyle = {
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
}

function Toast(message = '', type = TOAST_TYPE.DEFAULT, position = TOAST_POSITION.TOP_RIGHT, customStyle = {}) {
  if (!message || !type || !position) return

  toast[type](message, {
    position: position,
    ...defaultStyle,
    ...customStyle
  })
}

export { TOAST_TYPE, TOAST_POSITION }
export default Toast
