import { useCallback, useState } from "react";


export function useBoolean(defaultValue = false) {
  const [isTrue, setBoolean] = useState(defaultValue);
  const setTrue = useCallback(() => {
    setBoolean(true);
  }, []);
  const setFalse = useCallback(() => {
    setBoolean(false);
  }, []);
  return [isTrue, setFalse, setTrue];
}
