export const changeShowAndHideLayer = (
  mapLayers = null,
  categoryOrLayer,
  active
) => {
  if (!mapLayers || categoryOrLayer.id === 0) {
    return mapLayers;
  }

  let result = { ...mapLayers };

  result = {
    ...result,
    layerCategories: result.layerCategories
      ? result.layerCategories.map(item => {
          let _item = onLoopChangeDataLayer(item, categoryOrLayer, active);
          return _item;
        })
      : [],
    layerSettings: result?.layerSettings
      ? result.layerSettings.map(item => {
          let _item = onLoopChangeDataLayer(item, categoryOrLayer, active);
          return _item;
        })
      : []
  };

  return result;
};

const onLoopChangeDataLayer = (
  data = null,
  categoryOrLayer,
  active = false
) => {
  if (!data) {
    return data;
  }
  let _item = data;
  if (_item.id === categoryOrLayer.id && _item.name === categoryOrLayer.name) {
    _item = {
      ..._item,
      active: !_item.active,
      disabled: false,
      isUpdated: true,
      layerCategories: _item.layerCategories
        ? _item.layerCategories.map(item => loop(item, !_item.active))
        : [],
      layerSettings: _item?.layerSettings
        ? _item.layerSettings.map(item => loop(item, !_item.active))
        : []
    };

    return _item;
  } else {
    _item = {
      ..._item,
      isUpdated: true,
      layerCategories: _item.layerCategories
        ? _item.layerCategories.map(item =>
            onLoopChangeDataLayer(item, categoryOrLayer, active)
          )
        : [],
      layerSettings: _item?.layerSettings
        ? _item.layerSettings.map(item =>
            onLoopChangeDataLayer(item, categoryOrLayer, active)
          )
        : []
    };
    return _item;
  }
};

const loop = (data, active = false) => {
  let _item = data;
  _item = {
    ..._item,
    active: false,
    disabled: active === true ? false : true,
    isUpdated: true,
    layerCategories: _item.layerCategories
      ? _item.layerCategories.map(item => loop(item, false))
      : [],
    layerSettings: _item?.layerSettings
      ? _item.layerSettings.map(item => loop(item, false))
      : []
  };

  return _item;
};
