import React from "react";
import { ScreenCode } from "src/constants/PermissionScreen";
import { PermissionContext } from "src/contexts/permission";
import { useUserPermission } from "src/hooks/useUserPermission";

interface Props {
  screenCode?: ScreenCode;
  children: any;
  showMessage?: boolean;
}

const PermissionGuard = ({ children, screenCode, showMessage }: Props) => {
  const { canView, canEdit, canSync } = useUserPermission(screenCode);
  if (canView)
    return (
      <PermissionContext.Provider value={{ canEdit, canSync }}>
        {children}
      </PermissionContext.Provider>
    );
  return showMessage ? (
    <div>
      Bạn không có quyền truy cập vào mục này, vui lòng liên hệ Admin để được
      cấp quyền.
    </div>
  ) : null;
};

export default PermissionGuard;
