import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onOk: () => void;
  okLabel?: string;
  title?: string;
  children?: any;
}

export const Modal = ({
  isOpen,
  onClose,
  children,
  okLabel,
  title,
  onOk,
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Hủy
        </Button>
        <Button onClick={onOk} variant="contained" color="primary">
          {okLabel || "Đồng ý"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
