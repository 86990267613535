//@ts-check
import Toast, { TOAST_TYPE } from "src/components/Toast";

export const errorNotification = message => {
  Toast(message, TOAST_TYPE.ERROR);
};

export const successNotification = message => {
  Toast(message, TOAST_TYPE.SUCCESS);
};
