export const addCategoryForLayer = (data, mapLayers, openAddOrEditCategory) => {
  let _mapLayers = mapLayers;
  if (openAddOrEditCategory.listIndex.length === 1) {
    _mapLayers = {
      ..._mapLayers,
      layerCategories: _mapLayers.layerCategories.map(
        (layerCategory, index) => {
          if (index === openAddOrEditCategory.listIndex[0]) {
            if (openAddOrEditCategory.isCreate) {
              return {
                ...layerCategory,
                isUpdated: true,
                layerCategories: layerCategory.layerCategories.concat([
                  {
                    id: -new Date().getTime(),
                    name: data.categoryName,
                    level: 2,
                    ordinal: 1,
                    layerSettings: [],
                    layerCategories: [],
                    active: true,
                    isAdded: true,
                    isUpdated: false,
                    isDeleted: false
                  }
                ])
              };
            } else {
              return {
                ...layerCategory,
                name: data.categoryName,
                isUpdated: true
              };
            }
          }

          return layerCategory;
        }
      )
    };
  } else if (openAddOrEditCategory.listIndex.length === 2) {
    _mapLayers = {
      ..._mapLayers,
      layerCategories: _mapLayers.layerCategories.map(
        (layerCategoryLevel1, indexLevel1) => {
          if (indexLevel1 === openAddOrEditCategory.listIndex[0]) {
            return {
              ...layerCategoryLevel1,
              isUpdated: true,
              layerCategories: layerCategoryLevel1.layerCategories.map(
                (layerCategoryLevel2, indexLevel2) => {
                  if (indexLevel2 === openAddOrEditCategory.listIndex[1]) {
                    if (openAddOrEditCategory.isCreate) {
                      return {
                        ...layerCategoryLevel2,
                        isAdded: layerCategoryLevel2.id > 0 ? false : true,
                        isUpdated: layerCategoryLevel2.id > 0 ? true : false,
                        layerCategories: layerCategoryLevel2.layerCategories.concat(
                          [
                            {
                              id: -new Date().getTime(),
                              name: data.categoryName,
                              level: 3,
                              ordinal: 1,
                              layerSettings: [],
                              layerCategories: [],
                              active: true,
                              isAdded: true,
                              isUpdated: false,
                              isDeleted: false
                            }
                          ]
                        )
                      };
                    } else {
                      return {
                        ...layerCategoryLevel2,
                        name: data.categoryName,
                        isUpdated: true
                      };
                    }
                  }

                  return layerCategoryLevel2;
                }
              )
            };
          }

          return layerCategoryLevel1;
        }
      )
    };
  } else if (openAddOrEditCategory.listIndex.length === 3) {
    _mapLayers = {
      ..._mapLayers,
      layerCategories: _mapLayers.layerCategories.map(
        (layerCategoryLevel1, indexLevel1) => {
          if (indexLevel1 === openAddOrEditCategory.listIndex[0]) {
            return {
              ...layerCategoryLevel1,
              isUpdated: true,
              layerCategories: layerCategoryLevel1.layerCategories.map(
                (layerCategoryLevel2, indexLevel2) => {
                  if (indexLevel2 === openAddOrEditCategory.listIndex[1]) {
                    return {
                      ...layerCategoryLevel2,
                      isUpdated: true,
                      layerCategories: layerCategoryLevel2.layerCategories.map(
                        (layerCategoryLevel3, indexLevel3) => {
                          if (
                            indexLevel3 === openAddOrEditCategory.listIndex[2]
                          ) {
                            return {
                              ...layerCategoryLevel3,
                              name: data.categoryName,
                              isUpdated: true
                            };
                          }
                          return layerCategoryLevel3;
                        }
                      )
                    };
                  }
                  return layerCategoryLevel2;
                }
              )
            };
          }

          return layerCategoryLevel1;
        }
      )
    };
  }

  return _mapLayers;
};
