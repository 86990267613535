import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";
import MainLayout from "src/layouts/MainLayout";
import { ROUTER_NAME } from "src/constants";
import PermissionGuard from "./components/PermissionGuard";
import { IRoute } from "./models/IRoute";
import { ScreenCode } from "./constants/PermissionScreen";

export const renderRoutes = (routes: IRoute[] = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <PermissionGuard screenCode={route.screenCode} showMessage>
                      <Component {...props} />
                    </PermissionGuard>
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: IRoute[] = [
  {
    exact: true,
    path: ROUTER_NAME.NOT_FOUND,
    component: lazy(() => import("src/views/Errors/NotFoundView")),
  },

  {
    exact: true,
    guard: GuestGuard,
    path: ROUTER_NAME.LOGIN,
    component: lazy(() => import("src/views/Login")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ROUTER_NAME.REGISTER,
    component: lazy(() => import("src/views/Register")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ROUTER_NAME.FORGOT_PASSWORD,
    component: lazy(() => import("src/views/ForgotPassword")),
  },

  {
    exact: true,
    path: ROUTER_NAME.RESET_PASSWORD,
    component: lazy(() => import("src/views/ResetPassword")),
  },
  {
    path: ROUTER_NAME.ALL,
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: ROUTER_NAME.DEFAULT,
        component: lazy(() => import("src/views/MapLayer")),
        screenCode: ScreenCode.MAP_LAYER,
      },
      {
        exact: true,
        path: ROUTER_NAME.PRICE_FOR_EACH_PLOT,
        component: lazy(() => import("src/views/PriceForEachPlot")),
        screenCode: ScreenCode.LAND_PRICE_FOR_EACH_PLOT,
      },
      {
        exact: true,
        path: ROUTER_NAME.ACCOUNT_TYPE,
        component: lazy(() => import("src/views/AccountType")),
        screenCode: ScreenCode.ACCOUNT_TYPE,
      },
      {
        exact: true,
        path: ROUTER_NAME.LAND_TYPE,
        component: lazy(() => import("src/views/LandType")),
        screenCode: ScreenCode.SOIL_TYPE,
      },
      {
        exact: true,
        path: ROUTER_NAME.LAND_PRICE_TABLE,
        component: lazy(() => import("src/views/LandPriceTable")),
        screenCode: ScreenCode.LAND_PRICE_TABLE,
      },
      {
        exact: true,
        path: ROUTER_NAME.AUCTION_PROJECT,
        component: lazy(() => import("src/views/AuctionProject")),
        screenCode: ScreenCode.AUCTION_PROJECT,
      },
      {
        exact: true,
        path: ROUTER_NAME.SYSTEM_LOG,
        component: lazy(() => import("src/views/SystemLogs")),
        screenCode: ScreenCode.SYSTEM_LOGS,
      },
      {
        exact: true,
        path: ROUTER_NAME.TOOL,
        component: lazy(() => import("src/views/ToolManagement")),
        screenCode: ScreenCode.TOOL,
      },
      {
        exact: true,
        path: ROUTER_NAME.TOOL_TYPE,
        component: lazy(() => import("src/views/ToolTypeManagement")),
        screenCode: ScreenCode.TOOL_TYPE,
      },
      {
        exact: true,
        path: ROUTER_NAME.MAP_LAYER,
        component: lazy(() => import("src/views/MapLayer")),
        screenCode: ScreenCode.MAP_LAYER,
      },
      {
        exact: true,
        path: ROUTER_NAME.MAP_DOCUMENT,
        component: lazy(() => import("src/views/MapManagement")),
        screenCode: ScreenCode.MAP_DOCUMENTS,
      },
      {
        exact: true,
        path: ROUTER_NAME.DOCUMENT,
        component: lazy(() => import("src/views/DocumentView")),
        screenCode: ScreenCode.DOCUMENTS,
      },
      {
        exact: true,
        path: ROUTER_NAME.NEWS,
        component: lazy(() => import("src/views/News")),
        screenCode: ScreenCode.NEWS,
      },
      {
        exact: true,
        path: ROUTER_NAME.MANAGE_LAND_PRICE,
        component: lazy(() => import("src/views/ManageLandPrice")),
        screenCode: ScreenCode.LAND_PRICE,
      },
      {
        exact: true,
        path: ROUTER_NAME.USER_MANAGEMENT,
        component: lazy(() => import("src/views/UserManagement")),
        screenCode: ScreenCode.MANAGER_USER,
      },
      {
        exact: true,
        path: ROUTER_NAME.GUIDE,
        component: lazy(() => import("src/views/Guide")),
        screenCode: ScreenCode.GUIDE,
      },
      {
        exact: true,
        path: ROUTER_NAME.TERM,
        component: lazy(() => import("src/views/TermView")),
        screenCode: ScreenCode.RULES,
      },
      {
        exact: true,
        path: ROUTER_NAME.FEEDBACK,
        component: lazy(() => import("src/views/Feedback")),
        screenCode: ScreenCode.FEEDBACK_LIST,
      },
      {
        exact: true,
        path: ROUTER_NAME.BASE_MAP_SETTING,
        component: lazy(() => import("src/views/BaseMapSettingView")),
        screenCode: ScreenCode.BASE_MAP,
      },
      {
        exact: true,
        path: ROUTER_NAME.NORMAL_INFO,
        component: lazy(() => import("src/views/NormalInfoView")),
        screenCode: ScreenCode.COMMON_INFO,
      },
      {
        exact: true,
        path: ROUTER_NAME.FILE_ATTACHMENT,
        component: lazy(() => import("src/views/FileAttachment")),
        screenCode: ScreenCode.FILE_ATTACHMENT,
      },
      {
        exact: true,
        path: ROUTER_NAME.FEEDBACK_INFO,
        component: lazy(() => import("src/views/FeedbackInfoView")),
        screenCode: ScreenCode.FEEDBACK_CONFIG,
      },
      {
        exact: true,
        path: ROUTER_NAME.UPDATE_GOVERNMENT_PRICE,
        component: lazy(() => import("src/views/UpdateGovernmentPrice")),
        screenCode: ScreenCode.GOVERNMENT_PRICE,
      },
      {
        exact: true,
        path: ROUTER_NAME.UPDATE_INVESTIGATE_PRICE,
        component: lazy(() => import("src/views/UpdateInvestigatePrice")),
        screenCode: ScreenCode.INVESTIGATE_PRICE,
      },
      {
        exact: true,
        path: ROUTER_NAME.WARDS_MANAGE,
        component: lazy(() => import("src/views/Wards")),
        screenCode: ScreenCode.ADMINISTRATIVE_UNITS,
      },
      {
        //@ts-ignore
        component: () => <Redirect to={ROUTER_NAME.NOT_FOUND} />,
      },
    ],
  },
];

export default routes;
