export const editLayer = (data, mapLayers, openEditLayer) => {
  let _mapLayers = mapLayers;
  if (openEditLayer.listIndex.length === 1) {
    _mapLayers = {
      ..._mapLayers,
      layerCategories: _mapLayers.layerCategories.map(
        (layerCategory, index) => {
          if (index === openEditLayer.listIndex[0]) {
            return {
              ...layerCategory,
              isUpdated: true,
              layerSettings: layerCategory.layerSettings.map(layerSetting => {
                if (layerSetting.id === openEditLayer.data.id) {
                  return {
                    ...layerSetting,
                    ...data,
                    isUpdated: true
                  };
                }

                return layerSetting;
              })
            };
          }
          return layerCategory;
        }
      )
    };
  } else if (openEditLayer.listIndex.length === 2) {
    _mapLayers = {
      ..._mapLayers,
      layerCategories: _mapLayers.layerCategories.map(
        (layerCategoryLevel1, indexLevel1) => {
          if (indexLevel1 === openEditLayer.listIndex[0]) {
            return {
              ...layerCategoryLevel1,
              isUpdated: true,
              layerCategories: layerCategoryLevel1.layerCategories.map(
                (layerCategoryLevel2, indexLevel2) => {
                  if (indexLevel2 === openEditLayer.listIndex[1]) {
                    return {
                      ...layerCategoryLevel2,
                      isUpdated: true,
                      layerSettings: layerCategoryLevel2.layerSettings.map(
                        layerSetting => {
                          if (layerSetting.id === openEditLayer.data.id) {
                            return {
                              ...layerSetting,
                              ...data,
                              isUpdated: true
                            };
                          }

                          return layerSetting;
                        }
                      )
                    };
                  }

                  return layerCategoryLevel2;
                }
              )
            };
          }

          return layerCategoryLevel1;
        }
      )
    };
  } else if (openEditLayer.listIndex.length === 3) {
    _mapLayers = {
      ..._mapLayers,
      layerCategories: _mapLayers.layerCategories.map(
        (layerCategoryLevel1, indexLevel1) => {
          if (indexLevel1 === openEditLayer.listIndex[0]) {
            return {
              ...layerCategoryLevel1,
              isUpdated: true,
              layerCategories: layerCategoryLevel1.layerCategories.map(
                (layerCategoryLevel2, indexLevel2) => {
                  if (indexLevel2 === openEditLayer.listIndex[1]) {
                    return {
                      ...layerCategoryLevel2,
                      isUpdated: true,
                      layerCategories: layerCategoryLevel2.layerCategories.map(
                        (layerCategoryLevel3, indexLevel3) => {
                          if (indexLevel3 === openEditLayer.listIndex[2]) {
                            return {
                              ...layerCategoryLevel3,
                              isUpdated: true,
                              layerSettings: layerCategoryLevel3.layerSettings.map(
                                layerSetting => {
                                  if (
                                    layerSetting.id === openEditLayer.data.id
                                  ) {
                                    return {
                                      ...layerSetting,
                                      ...data,
                                      isUpdated: true
                                    };
                                  }

                                  return layerSetting;
                                }
                              )
                            };
                          }

                          return layerCategoryLevel2;
                        }
                      )
                    };
                  }

                  return layerCategoryLevel2;
                }
              )
            };
          }

          return layerCategoryLevel1;
        }
      )
    };
  }

  return _mapLayers;
};
