export enum FieldName {
  OldPassword = "CurrentPassword",
  NewPassword = "NewPassword",
  ReInputNewPassword = "ReInputNewPassword",
  FullName = "fullName",
  Email = "email",
  Address = "address",
  PhoneNumber = "phoneNumber",
  Description = "description",
  DateOfBirth = "dateOfBirth",
  Gender = "sex",
  AccountType = "accountType",
  Label = "label",
  Code = "code",
  IsActive = "isActive",
  Name = "name",
  RoleId = "roleId",
  ParentCode = "parentCode",
  ParentTitle = "parentTitle",
  Time = "time",
  Account = "account",
  Action = "action",
  Object = "object",
  Title = "title",
  AuctionAddress = "auctionAddress",
  AuctionOrganization = "auctionOrganization",
  AuctionTime = "auctionTime",
  AuctionUnit = "auctionUnit",
  ProjectCode = "projectCode",
  ProjectName = "projectName",
  Id = "id",
  Level = "level",
  Table = "table",
  TableName = "tableName",
  UserName = "userName",
  CreatedDate = "createdDate",
  FileName = "fileName",
  Size = "size",
  ReleaseDate = "releaseDate",
  EffectiveDate = "effectiveDate",
  Price = "price",
  LandLocation = "landLocation",
  LandArea = "landArea",
  PriceAccordingList = "priceAccordingList",
  Area = "area",
  PublishDate = "publishDate",
  Location = "location",
  LandId = "landId",
  ModifiedDate = "modifiedDate",
  ParcelId = "parcelId",
  StatePrice = "statePrice",
  DetailPrice = "detailPrice",
  MarketPrice = "marketPrice",
  Geom = "geom",
  CommuneCode = "communeCode",
  MapNumber = "mapNumber",
  ParcelNumber = "parcelNumber",
  CommuneColumn = "communeCode",
  MapNumberColumn = "mapNumber",
  ParcelNumberColumn = "parcelNumber",
  MarketPriceColumn = "marketPrice",
  GeomColumn = "geom",
  ContractPrice = "transferContractPrice",
  ContractTime = "transferContractTime",
  AuctionPrice = "auctionPrice",
  MarketPriceTIme = "transferTime",
  Tables = "tables",
}
