import React from "react";
import { Box, Drawer, List, makeStyles, Divider } from "@material-ui/core";
import NavItem from "./NavItem";
import { ROUTER_NAME } from "src/constants";

//--- Icon
import BuildIcon from "@material-ui/icons/Build";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import AssignmentIcon from "@material-ui/icons/Assignment";
import GavelIcon from "@material-ui/icons/Gavel";
import FeedbackIcon from "@material-ui/icons/Feedback";
import MapIcon from "@material-ui/icons/Map";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import PolymerIcon from "@material-ui/icons/Polymer";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import PermissionGuard from "src/components/PermissionGuard";
import PublishIcon from "@material-ui/icons/Publish";
import ListIcon from "@material-ui/icons/List";
import GroupIcon from "@material-ui/icons/Group";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import { INavItemConfig } from "src/models/INavItemConfig";
import { ScreenCode } from "src/constants/PermissionScreen";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
const items: INavItemConfig[] = [
  {
    title: "Quản lý công cụ",
    icon: BuildIcon,
    showBorder: true,
    subLinks: [
      {
        href: ROUTER_NAME.TOOL,
        icon: FiberManualRecordIcon,
        title: "Công cụ",
        showBorder: true,
        screenCode: ScreenCode.TOOL,
      },
      {
        href: ROUTER_NAME.TOOL_TYPE,
        icon: FiberManualRecordIcon,
        title: "Loại công cụ",
        showBorder: true,
        screenCode: ScreenCode.TOOL_TYPE,
      },
    ],
  },
  //
  {
    title: "Quản lý bản đồ",
    icon: MapIcon,
    showBorder: true,
    subLinks: [
      {
        href: ROUTER_NAME.MAP_LAYER,
        icon: FiberManualRecordIcon,
        title: "Lớp bản đồ",
        showBorder: true,
        screenCode: ScreenCode.MAP_LAYER,
      },
      {
        href: ROUTER_NAME.BASE_MAP_SETTING,
        icon: FiberManualRecordIcon,
        title: "Bản đồ nền",
        showBorder: true,
        screenCode: ScreenCode.BASE_MAP,
      },
    ],
  },
  // {
  //   href: ROUTER_NAME.MAP_LAYER,
  //   icon: LayersIcon,
  //   title: "Lớp bản đồ",
  //   showBorder: true
  // },
  // {
  //   href: ROUTER_NAME.BASE_MAP_SETTING,
  //   icon: MapIcon,
  //   title: "Quản lý Bản đồ nền",
  //   showBorder: true
  // },
  {
    title: "Quản lý tài liệu",
    icon: NoteAddIcon,
    showBorder: true,
    subLinks: [
      {
        href: ROUTER_NAME.MAP_DOCUMENT,
        icon: FiberManualRecordIcon,
        title: "Tài liệu Bản đồ",
        showBorder: true,
        screenCode: ScreenCode.MAP_DOCUMENTS,
      },
      {
        href: ROUTER_NAME.DOCUMENT,
        icon: FiberManualRecordIcon,
        title: "Tài liệu Văn bản",
        showBorder: true,
        screenCode: ScreenCode.DOCUMENTS,
      },
    ],
  },
  // {
  //   href: ROUTER_NAME.MAP_DOCUMENT,
  //   icon: NoteAddIcon,
  //   title: "Tài liệu Bản đồ",
  //   showBorder: true
  // },
  // {
  //   href: ROUTER_NAME.DOCUMENT,
  //   icon: NoteAddIcon,
  //   title: "Tài liệu Văn bản",
  //   showBorder: true
  // },
  {
    href: ROUTER_NAME.LAND_PRICE_TABLE,
    icon: ViewQuiltIcon,
    title: "Quản lý bảng giá đất",
    showBorder: true,
    screenCode: ScreenCode.LAND_PRICE_TABLE,
  },
  {
    href: ROUTER_NAME.LAND_TYPE,
    icon: ViewQuiltIcon,
    title: "Quản lý loại đất",
    showBorder: true,
    screenCode: ScreenCode.SOIL_TYPE,
  },
  {
    href: ROUTER_NAME.FILE_ATTACHMENT,
    icon: AttachFileIcon,
    title: "Quản lý tệp đính kèm",
    showBorder: true,
    screenCode: ScreenCode.FILE_ATTACHMENT,
  },
  {
    href: ROUTER_NAME.NEWS,
    icon: FiberNewIcon,
    title: "Quản lý tin tức",
    showBorder: true,
    screenCode: ScreenCode.NEWS,
  },
  {
    href: ROUTER_NAME.AUCTION_PROJECT,
    icon: LocalAtmIcon,
    title: "Quản lý dự án đấu giá",
    showBorder: true,
    screenCode: ScreenCode.AUCTION_PROJECT,
  },
  // {
  //   href: ROUTER_NAME.MANAGE_LAND_PRICE,
  //   icon: MonetizationOnIcon,
  //   title: "Quản lý giá đất",
  //   showBorder: true,
  //   screenCode: ScreenCode.LAND_PRICE,
  // },
  // {
  //   href: ROUTER_NAME.UPDATE_GOVERNMENT_PRICE,
  //   icon: PublishIcon,
  //   title: "Cập nhật giá nhà nước",
  //   showBorder: true,
  //   screenCode: ScreenCode.GOVERNMENT_PRICE,
  // },
  // {
  //   href: ROUTER_NAME.UPDATE_INVESTIGATE_PRICE,
  //   icon: PublishIcon,
  //   title: "Cập nhật giá điều tra",
  //   showBorder: true,
  //   screenCode: ScreenCode.INVESTIGATE_PRICE,
  // },
  {
    href: ROUTER_NAME.WARDS_MANAGE,
    icon: ListIcon,
    title: "Quản lý đơn vị hành chính",
    showBorder: true,
    screenCode: ScreenCode.ADMINISTRATIVE_UNITS,
  },
  {
    href: ROUTER_NAME.PRICE_FOR_EACH_PLOT,
    icon: MonetizationOnIcon,
    title: "Quản lý giá đất tới từng thửa đất",
    showBorder: true,
    screenCode: ScreenCode.LAND_PRICE_FOR_EACH_PLOT,
  },
  {
    href: ROUTER_NAME.USER_MANAGEMENT,
    icon: AccountCircleIcon,
    title: "Quản lý người dùng",
    showBorder: true,
    screenCode: ScreenCode.MANAGER_USER,
  },
  {
    href: ROUTER_NAME.ACCOUNT_TYPE,
    icon: GroupIcon,
    title: "Quản lý loại tài khoản",
    showBorder: true,
    screenCode: ScreenCode.ACCOUNT_TYPE,
  },
  {
    href: ROUTER_NAME.SYSTEM_LOG,
    icon: GroupIcon,
    title: "Nhật ký hệ thống",
    showBorder: true,
    screenCode: ScreenCode.SYSTEM_LOGS,
  },
  {
    title: "Quản lý khác",
    icon: InboxIcon,
    subLinks: [
      {
        href: ROUTER_NAME.TERM,
        icon: GavelIcon,
        title: "Điều khoản",
        showBorder: true,
        screenCode: ScreenCode.RULES,
      },
      {
        href: ROUTER_NAME.GUIDE,
        icon: AssignmentIcon,
        title: "Hướng dẫn",
        showBorder: true,
        screenCode: ScreenCode.GUIDE,
      },
      {
        href: ROUTER_NAME.FEEDBACK,
        icon: FeedbackIcon,
        title: "Phản hồi",
        showBorder: true,
        screenCode: ScreenCode.FEEDBACK_LIST,
      },
      {
        href: ROUTER_NAME.NORMAL_INFO,
        icon: PolymerIcon,
        title: "Thay đổi logo",
        showBorder: true,
        screenCode: ScreenCode.COMMON_INFO,
      },
      {
        href: ROUTER_NAME.FEEDBACK_INFO,
        icon: ContactSupportIcon,
        title: "Thay đổi góp ý",
        showBorder: true,
        screenCode: ScreenCode.FEEDBACK_CONFIG,
      },
    ],
  },
];

const useStyles = makeStyles(() => ({
  desktopDrawer: {
    width: 240,
    top: 70,
    height: "calc(100% - 64px)",
    backgroundColor: "#354ea9",
  },
}));

const NavBar = () => {
  const classes = useStyles();

  return (
    <Drawer
      open
      anchor="left"
      classes={{ paper: classes.desktopDrawer }}
      variant="persistent"
    >
      <Box height="100%" display="flex" flexDirection="column">
        <Box p={2}>
          <List>
            <Divider style={{ backgroundColor: "rgb(255 255 255 / 12%)" }} />
            {items.map((item) => (
              <PermissionGuard key={item.title} screenCode={item.screenCode}>
                <NavItem
                  href={item.href}
                  title={item.title}
                  icon={item.icon}
                  subLinks={item?.subLinks || null}
                  {...item}
                />
              </PermissionGuard>
            ))}
          </List>
        </Box>
        <Box flexGrow={1} />
      </Box>
    </Drawer>
  );
};

export default NavBar;
