import moment from "moment";
import { DateTimeFormat } from "src/constants/DateTimeFormat";

export * from "./LocalStorage";
export * from "./mapData";

export const downloadFile = (fileUrl = "", fileName = "") => {
  const isFileUrlIValid = typeof fileUrl !== "string" || !fileUrl.trim();
  if (isFileUrlIValid) {
    console.error("fileUrl must be string, but got ", fileUrl);
    return;
  }
  const linkElement = document.createElement("a");
  linkElement.setAttribute("download", fileName || "");
  linkElement.href = fileUrl;
  linkElement.click();
  linkElement.remove();
};

export const getErrorMsg = error =>
  error?.errorMessage ?? "Đã có lỗi xảy ra, vui lòng thử lại sau";

export const getApiDateTimeString = date =>
  moment(date).format(DateTimeFormat.DateMonthYear);

export const getApiDateTimeStringRevert = date =>
  moment(date).format(DateTimeFormat.YearMonthDate);

export const formatDateInputRequestBody = value =>
  value ? getApiDateTimeStringRevert(value) : "";

export const formatDateDisplay = value =>
  value ? getApiDateTimeString(value) : "";
