import { ScreenCode } from "src/constants/PermissionScreen";
import useAuth from "./useAuth";
import { useMemo } from "react";
import { PermissionType } from "src/constants/PermissionType";

export const useUserPermission = (screenCode?: ScreenCode | ScreenCode[]) => {
  const { user } = useAuth();
  const permissions = useMemo(() => {
    if (!screenCode)
      return {
        canView: true,
        canEdit: true,
        canSync: true,
      };
    if (Array.isArray(screenCode)) {
      let canEdit = false;
      let canView = false;
      let canSync = false;
      screenCode.forEach((screenCodeCheck) => {
        if (!canEdit && !canView && !canSync) {
          const screenConfig = user?.actionScreens?.find(
            (screen) => screen.screenCode === screenCodeCheck
          );
          if (screenConfig?.action) {
            canSync = screenConfig.action === PermissionType.SyncToMainTable;
            canEdit = canSync || screenConfig.action === PermissionType.Edit;
            canView = canEdit || screenConfig.action === PermissionType.View;
          }
        }
      });
      return {
        canEdit,
        canView: canEdit || canView || canSync,
        canSync,
      };
    }
    const screenConfig = user?.actionScreens?.find(
      (screen) => screen.screenCode === screenCode
    );
    if (screenConfig?.action) {
      const canSync = screenConfig.action === PermissionType.SyncToMainTable;
      const canEdit = canSync || screenConfig.action === PermissionType.Edit;
      const canView = canEdit || screenConfig.action === PermissionType.View;
      return { canEdit, canView, canSync };
    }
    return {
      canView: false,
      canEdit: false,
      canSync: false,
    };
  }, []);
  return permissions;
};
