export const changeShowAndHideBaseMap = (baseMaps = null, idChange = 0) => {
  if (!baseMaps || idChange === 0) {
    return baseMaps;
  }

  let result = baseMaps.map(item => {
    let _item = {
      ...item
    };

    if (item.id === idChange) {
      _item = {
        ..._item,
        active: !_item.active,
        action: "UPDATE"
      };

      return _item;
    }

    return _item;
  });

  return result;
};

export const changeShowAndHideDefaultBaseMap = (
  baseMaps = null,
  idChange = 0,
  isShow = true
) => {
  if (!baseMaps || idChange === 0) {
    return baseMaps;
  }

  let result = baseMaps.map((item, index) => {
    let _item = {
      ...item
    };

    if (!isShow && index === 0 && item.id !== idChange) {
      _item = {
        ..._item,
        isViewDefault: true,
        action: "UPDATE"
      };

      return _item;
    }

    if (
      !isShow &&
      index === 1 &&
      baseMaps.findIndex(map => map.id === idChange) === 0
    ) {
      _item = {
        ..._item,
        isViewDefault: true,
        action: "UPDATE"
      };

      return _item;
    }

    if (item.id === idChange) {
      _item = {
        ..._item,
        isViewDefault: isShow,
        action: "UPDATE"
      };

      return _item;
    }

    if (isShow) {
      _item = {
        ..._item,
        isViewDefault: false,
        action: "UPDATE"
      };

      return _item;
    }

    return _item;
  });

  return result;
};
