export const addLayer = (data, mapLayers, openAddLayer) => {
  let _mapLayers = mapLayers;
  if (openAddLayer.listIndex.length === 1) {
    _mapLayers = {
      ..._mapLayers,
      layerCategories: _mapLayers.layerCategories.map(
        (layerCategory, index) => {
          if (index === openAddLayer.listIndex[0]) {
            return {
              ...layerCategory,
              isUpdated: true,
              layerSettings: layerCategory.layerSettings.concat([
                {
                  ...data,
                  active: true
                }
              ])
            };
          }
          return layerCategory;
        }
      )
    };
  } else if (openAddLayer.listIndex.length === 2) {
    _mapLayers = {
      ..._mapLayers,
      layerCategories: _mapLayers.layerCategories.map(
        (layerCategoryLevel1, indexLevel1) => {
          if (indexLevel1 === openAddLayer.listIndex[0]) {
            return {
              ...layerCategoryLevel1,
              isUpdated: true,
              layerCategories: layerCategoryLevel1.layerCategories.map(
                (layerCategoryLevel2, indexLevel2) => {
                  if (indexLevel2 === openAddLayer.listIndex[1]) {
                    return {
                      ...layerCategoryLevel2,
                      isUpdated: true,
                      layerSettings: layerCategoryLevel2.layerSettings.concat([
                        {
                          ...data,
                          active: true
                        }
                      ])
                    };
                  }

                  return layerCategoryLevel2;
                }
              )
            };
          }

          return layerCategoryLevel1;
        }
      )
    };
  } else if (openAddLayer.listIndex.length === 3) {
    _mapLayers = {
      ..._mapLayers,
      layerCategories: _mapLayers.layerCategories.map(
        (layerCategoryLevel1, indexLevel1) => {
          if (indexLevel1 === openAddLayer.listIndex[0]) {
            return {
              ...layerCategoryLevel1,
              isUpdated: true,
              layerCategories: layerCategoryLevel1.layerCategories.map(
                (layerCategoryLevel2, indexLevel2) => {
                  if (indexLevel2 === openAddLayer.listIndex[1]) {
                    return {
                      ...layerCategoryLevel2,
                      isUpdated: true,
                      layerCategories: layerCategoryLevel2.layerCategories.map(
                        (layerCategoryLevel3, indexLevel3) => {
                          if (indexLevel3 === openAddLayer.listIndex[2]) {
                            return {
                              ...layerCategoryLevel3,
                              isUpdated: true,
                              layerSettings: layerCategoryLevel3.layerSettings.concat(
                                [
                                  {
                                    ...data,
                                    active: true
                                  }
                                ]
                              )
                            };
                          }

                          return layerCategoryLevel2;
                        }
                      )
                    };
                  }

                  return layerCategoryLevel2;
                }
              )
            };
          }

          return layerCategoryLevel1;
        }
      )
    };
  }

  return _mapLayers;
};
